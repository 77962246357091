import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {AlertIcon, ChevronRightIcon, ToolsIcon, CheckCircleIcon, XIcon} from '@primer/octicons-react'
import moment from 'moment';
import './App.css';

var hideIncidents = [];
var localStorageAvailable = true
try {
  localStorage.setItem('test', 'test');
  localStorage.removeItem('test');
} catch(e) {
  localStorageAvailable = false;
}

function sendMessage(message, id){
  if (message === 'dismissFrame'){
    if (localStorageAvailable) {
      hideIncidents.push(id);
      localStorage.setItem('hideIncidents', hideIncidents);
    } else {
      console.log("You dismissed the flag, but third party cookies are disabled on your browser so the flag will come back the next time you visit the page.")
    }
  }
  // eslint-disable-next-line no-restricted-globals
  parent.postMessage({action: message},'*');
}

const Home = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [components, setComponents] = useState([]);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const summaryUrl = (process.env.REACT_APP_SUMMARY_URL) ? process.env.REACT_APP_SUMMARY_URL : "https://status.mistral-data.com/api/v2/summary.json";
    fetch(summaryUrl)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setPages(result.page);
          setComponents(result.components);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="App">
        <h1>
          {pages.name}
        </h1>
        {components.map(component => (
          <div className={'list ' + component.status} key={component.id}>
            <Link to={'/component/' + component.id}>{component.name} - {component.status}</Link>
          </div>
        ))}
      </div>
    );
  }
};

const Component = () => {
  const {id} = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [incidents, setIncidents] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  var affectedIncident, affectedIncidentId, affectedMaintenance, affectedMaintenanceId;

  var ids = id.split(',');

  useEffect(() => {
    const summaryUrl = (process.env.REACT_APP_SUMMARY_URL) ? process.env.REACT_APP_SUMMARY_URL : "https://status.mistral-data.com/api/v2/summary.json";
    const interval = setInterval(() => {
      fetch(summaryUrl)
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setIncidents(result.incidents);
            setMaintenances(result.scheduled_maintenances);
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        )
    }, 10000);
    return () => clearInterval(interval);
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    var affectedIncidentCount = 0;
    var affectedMaintenanceCount = 0;

    if (incidents.length > 0){
      for (let i = 0; i < incidents.length; i++){
        for (let c = 0; c < incidents[i].components.length; c++){
          if (ids.includes(incidents[i].components[c].id)){
            affectedIncident = incidents[i].impact;
            affectedIncidentId = i;
            affectedIncidentCount++;
          }
        }
      }

      if (affectedIncident){
        if (!localStorage.getItem('hideIncidents').includes(incidents[affectedIncidentId].id)){
          sendMessage('showFrame');
        }
        if (affectedIncidentCount > 1){
          return (
            <div className="App affected major">
              <div>
                <div className="icon">
                  <AlertIcon />
                </div>
                <div className="info">
                  <h1 className='title'>Multiple issues</h1>
                  <div className='close' onClick={() => sendMessage('dismissFrame', incidents[affectedIncidentId].id)}><XIcon /></div>
                  <p className='body'>A number of incidents are open. Please check our Statuspage for more details</p>
                  <div className='bottom'>
                    <p><a href="https://status.mistral-data.com" target="_blank" rel="noopener noreferrer"><ChevronRightIcon />View latest updates</a></p> 
                    <p className='lastupdated'>Last updated: {moment(incidents[affectedIncidentId].updated_at).format("dddd Do MMM, h.mmA")}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className={"App affected " + affectedIncident}>
              <div key={incidents[affectedIncidentId].id}>
                <div className="icon">
                  <AlertIcon />
                </div>
                <div className="info">
                  <h1 className='title'>{incidents[affectedIncidentId].name}</h1>
                  <div className='close' onClick={() => sendMessage('dismissFrame', incidents[affectedIncidentId].id)}><XIcon /></div>
                  <p className='body'>{incidents[affectedIncidentId].incident_updates[0].body}</p>
                  <div className='bottom'>
                    <p><a href={incidents[affectedIncidentId].shortlink} target="_blank" rel="noopener noreferrer"><ChevronRightIcon />View latest updates</a></p> 
                    <p className='lastupdated'>Last updated: {moment(incidents[affectedIncidentId].updated_at).format("dddd Do MMM, h.mmA")}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    } else if (maintenances.length > 0){
      for (let m = 0; m < maintenances.length; m++){
        for (let c = 0; c < maintenances[m].components.length; c++){
          if (ids.includes(maintenances[m].components[c].id)){
            affectedMaintenance = maintenances[m].impact;
            affectedMaintenanceId = m;
            affectedMaintenanceCount++;
          }
        }
      }

      if (affectedMaintenance){
        if (!localStorage.getItem('hideIncidents').includes(maintenances[affectedMaintenanceId].id)){
          sendMessage('showFrame');
        }
        if (affectedMaintenanceCount > 1){
          return (
            <div className={"App affected " + affectedMaintenance}>
              <div key={maintenances[affectedMaintenanceId].id}>
                <div className="icon">
                  <ToolsIcon />
                </div>
                <div className="info">
                  <h1 className='title'>Multiple maintenances</h1>
                  <div className='close' onClick={() => sendMessage('dismissFrame', maintenances[affectedMaintenanceId].id)}><XIcon /></div>
                  <p className='body'>A number of maintenance windows are open. Please check our Statuspage for more details</p>
                  <div className='bottom'>
                    <p><a href='https://status.mistral-data.com' target="_blank" rel="noopener noreferrer"><ChevronRightIcon />View latest updates</a></p> 
                    <p className='lastupdated'>Last updated: {moment(maintenances[affectedMaintenanceId].updated_at).format("dddd Do MMM, h.mmA")}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className={"App affected " + affectedMaintenance}>
              <div key={maintenances[affectedMaintenanceId].id}>
                <div className="icon">
                  <ToolsIcon />
                </div>
                <div className="info">
                  <h1 className='title'>{maintenances[affectedMaintenanceId].name}</h1>
                  <div className='close' onClick={() => sendMessage('dismissFrame', maintenances[affectedMaintenanceId].id)}><XIcon /></div>
                  <p className='lastupdated'>From {moment(maintenances[affectedMaintenanceId].scheduled_for).format("dddd Do MMM, h.mmA")} - until {moment(maintenances[affectedMaintenanceId].scheduled_until).format("dddd Do MMM, h.mmA")}</p>
                  <p className='body'>{maintenances[affectedMaintenanceId].incident_updates[0].body}</p>
                  <div className='bottom'>
                    <p><a href={maintenances[affectedMaintenanceId].shortlink} target="_blank" rel="noopener noreferrer"><ChevronRightIcon />View latest updates</a></p> 
                    <p className='lastupdated'>Last updated: {moment(maintenances[affectedMaintenanceId].updated_at).format("dddd Do MMM, h.mmA")}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }
    } else {
      sendMessage('dismissFrame');
      return (
        <div className="App unaffected">
          <div className="icon">
            <CheckCircleIcon  />
          </div>
          <div className="info">
            <h1>There are no issues.</h1>
          </div>
        </div>
      );
    }
  }
};

export {Home, Component};
