import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {Home, Component} from './App';
import './index.css';

ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Home/>} />
      <Route path="/component/:id" element={<Component/>} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
